import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import {language, trackLanguage} from './js/Language';
import './css/cssrest.css';
import Ads from 'vue-google-adsense'

Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)
Vue.use(require('vue-script2'))
Vue.use(ElementUI);
Vue.use(Vuetify);
Vue.config.productionTip = false;

Vue.prototype.$trackLanguage = trackLanguage;

Vue.prototype.$t = function(key: string, local: string) {
    try {
        const result = (language as any)[key][local];
        return result ? result : key;
    } catch (e) {
        return key;
    }
};


new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');
