





























































































































































































































import {Component, Vue} from "vue-property-decorator";
import Track from "@/components/track.vue";
import {Cargo, HttpUtils, Prods, Store} from "@/js/HttpUtils";

@Component({
    components: {
        "v-track": Track
    },
})
export default class App extends Vue {
    private isShow = true;
    private trackNumber = "";
    private host = "";
    private showErrMsg = false;
    private adsShow = false;
    private storeLiShow = true;
    private languageArr = [
        {text: "简体中文", locale: "zh-CN"},
        {text: "繁体中文", locale: "zh-hk"},
        {text: "English", locale: "en"},
        {text: "한국어", locale: "kr"},
        {text: "Deutsch", locale: "de"},
        {text: "Español", locale: "es"},
        {text: "Português", locale: "pt"},
        {text: "Français", locale: "fr"},
        {text: "русский", locale: "ru"},
    ];
    private activeName: string = "1";
    private language = this.languageArr[2];
    private httpUtils: HttpUtils = new HttpUtils();
    private cargo: Cargo[] | null = null;
    private rankingList: Prods[] = [];
    private logisticsNumber: string = '';
    private cargoDialog = false;
    private logisticsCargo: Cargo | null = null;
    private paddingTop: number | null = null;
    private footerBottom: number | null = null;
    private hasData: boolean = true;
    private showScroll: boolean = false;

    tracking() {
        if (!this.trackNumber) {
            this.showErrMsg = true;
            return;
        }
        this.trackNumber = this.trackNumber.trim().toLocaleUpperCase();
        // location.href = `${location.origin}${location.pathname}?num=${this.trackNumber}`;
        this.showErrMsg = false;
        this.isShow = true;
        this.host = location.host;
        console.log(this.host);
        let orderNumberUrl = this.$route.query.ordernum ? `&ordernum=${this.$route.query.ordernum}` : '';
        history.pushState({status: 0}, "", `?num=${this.trackNumber}${orderNumberUrl}`);

    }

    private scrollHandle() {
        const offset = (<HTMLElement>this.$refs['viewMore']).getBoundingClientRect();
        const offsetTop = offset.top;
        const offsetBottom = offset.bottom;

        if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
            this.initStore();
        }
    }

    destroyed(): void {
        this.cleanScroll();
    }

    private cleanScroll() {
        window.removeEventListener('scroll', this.scrollHandle, true);
    }

    async created() {
        let num = this.$route.query.num;
        if (!num) {
            this.isShow = false;
        } else {
            this.isShow = true;
        }

        let mail = this.$route.query.mail || '';
        if (!!mail) {
            this.storeLiShow = false;
        } else {
            this.storeLiShow = true;
        }

        if (!(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
            window.addEventListener("resize", () => {
                if (this.trackNumber) {
                    this.tracking();
                }
            }, false);
        }
        this.$nextTick(async () => {
            const element = <HTMLElement>this.$refs['storeContent'];
            await this.initStore();
            if (element.clientHeight < 600) {
                 element.style.height = "600px";
            } else {
                 element.style.height = element.clientHeight + "px";
            }
           
            if (this.device() === "pc") {
                window.addEventListener("scroll", this.scrollHandle, true);
            }
            // const footerElement = <HTMLElement>this.$refs['footer'];
            // this.footerBottom = footerElement.clientHeight + 20;
        });
        // this.cargo = await this.httpUtils.getCargo();

        this.trackNumber = <string>num;
        this.tracking();

        let adsData = await this.httpUtils.adsIsShow();
        //console.log('adsData',adsData);
        if(adsData){
            this.adsShow = true;
        }

        if (location.host.indexOf("track718.us") > -1) {
            let script = document.createElement("script")
            script.setAttribute("src", "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js")
            script.setAttribute("async", "")
            script.setAttribute("data-ad-client", "ca-pub-5438524816858113")
            document.body.appendChild(script)
        }

        if (this.adsShow && location.host.indexOf("track718.com") > -1) {
            let script = document.createElement("script")
            script.setAttribute("src", "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js")
            script.setAttribute("async", "")
            script.setAttribute("data-ad-client", "ca-pub-7233593539846104")
            document.body.appendChild(script)
        }

    }

    private loading: boolean = false;

    private async initStore() {
        if (this.loading) {
            return;
        }
        this.loading = true;
        try {
            const store = await this.httpUtils.getStore(this.buildParam());
            if (!store || !store.prods || store.prods.length === 0) {
                throw new Error("no data");
            }
            //store.prods[0].promotionDetailUrl = '//'+location.host+'/test.html';
            this.rankingList.push(...store.prods);
            this.loading = false;
        } catch (e) {
            this.loading = false;
            this.hasData = false;
            this.cleanScroll();
        }
    }

    private storeApiLanguage: any = {
        "zh-CN": "en",
        "zh-hk": "en",
        "es": "es",
        "en": "en",
        "kr": "kr",
        "de": "de",
        "pt": "pt",
        "fr": "fr",
        "ru": "ru",
    };

    private page: number = 1;

    private buildParam() {
        //console.log(document.referrer,',' ,window.location.href,document.referrer || window.location.href)
        return {
            lang: this.storeApiLanguage[this.language.locale],
            deviceType: this.device(),
            referrer: this.device() === "wap" ? "###m###"+(document.referrer || window.location.href) : (document.referrer || window.location.href),
            deviceAppType: this.device() === "wap" ? this.mobileType() : null,
            page: this.page++,
            pagesize: this.getPageSize(),
            // vid: "rBIKEFwkjw9nw3w0AwsA49==",
            siteCodeType: this.storeApiLanguage[this.language.locale],
            orderNo: this.$route.query.ordernum
        }
    }

    private getPageSize() {
        if (this.device() === "pc") {
            return "8";
        }
        return "2";
    }

    private viewMore() {
        if (this.loading) {
            return;
        }
        this.showScroll = true;
        this.$nextTick(() => {
            // window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域
            this.initStore();
        })
    }

    private mobileType() {
        const u = navigator.userAgent
        if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
            return "ios";
        } else if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
            return "android";
        } else if (u.indexOf('iPad') > -1) {
            return "ipad";
        } else {
            return "other";
        }
    }

    private device() {
        const sUserAgent = navigator.userAgent.toLowerCase();
        const bIsIpad = sUserAgent.indexOf("ipad") != -1;
        const bIsIphoneOs = sUserAgent.indexOf("iphone os") != -1;
        const bIsMidp = sUserAgent.indexOf("midp") != -1;
        const bIsUc7 = sUserAgent.indexOf("rv:1.2.3.4") != -1;
        const bIsUc = sUserAgent.indexOf("ucweb") != -1;
        const bIsAndroid = sUserAgent.indexOf("android") != -1;
        const bIsCE = sUserAgent.indexOf("windows ce") != -1;
        const bIsWM = sUserAgent.indexOf("windows mobile") != -1;

        if (!(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)) {
            return "pc";
        } else {
            return "wap";
        }
    }

    private openUrl(url: string) {
        window.open(url);
    }

    private chooseLanguage(item: { text: string, locale: string }) {
        this.language = item;
        this.initStore();
    }

    private getLanguage(key: string) {
        return this.$t(key, this.language.locale);
    }

    private isChinese() {
        return this.language.locale === 'zh-CN' || this.language.locale === 'zh-hk';
    }

    private autoLogistics() {
        this.cargoDialog = false;
        this.logisticsCargo = null;
    }

    private chooseLogistics(item: Cargo) {
        this.logisticsCargo = item;
        this.cargoDialog = false;
    }

    private showMore(paddingTop: number) {
        this.paddingTop = paddingTop;
    }

    private back() {
        this.isShow = false;
        this.paddingTop = null;
    }
}
