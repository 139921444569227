





























































































































    import {Component, Prop, Vue} from "vue-property-decorator";
    import {Cargo, HttpUtils, Status, TrackData} from "@/js/HttpUtils";
    import {transfromCityCn} from "@/js/country";

    interface SpecialLevelConfig {
        [key: string]: any
    }

    @Component
    export default class Track extends Vue {
        @Prop() trackNumber!: string;
        @Prop() language!: { text: string, locale: string };
        @Prop() logisticsCargo!: Cargo;
        private trackDataList: TrackData[] | null = null;
        private cargo: Cargo[] | null = null;
        private httpUtils: HttpUtils = new HttpUtils();
        private dialogVisible = false;
        private isChoose: boolean = false;
        private uuid = Math.random() + "-" + new Date().getTime();
        private host = "";
        private adsShow = false;
        private dialogEmailVisible = false;
        private emailInput = [
            {value: ''}
        ];

        async created(): Promise<void | null> {
            try {
                this.cargo = await this.httpUtils.getCargo();
                let adsData = await this.httpUtils.adsIsShow();
                //console.log('adsData2',adsData);
                if (adsData == true) {
                    this.adsShow = true;
                }

                let key: string | null = null;
                if (this.logisticsCargo) {
                    key = this.logisticsCargo.key;
                    this.isChoose = true;
                } else {
                    let {specialUser, specialLevelConfig} = await this.getSpecialLevelConf()
                    key = this.getKey(this.cargo!, specialUser, specialLevelConfig);
                    this.isChoose = false;
                }
                if (!key) {
                    this.trackDataList = [];
                    return null;
                }
                this.uuid = Math.random() + "-" + new Date().getTime();
                this.host = location.host;
                console.log(this.host);
                this.retryGetTrack(key, this.uuid);
                this.webSocketStart("wss://socket.track718.net/");
            } catch (err) {
                console.log('created error:', err)
            }

        }

        private async getSpecialLevelConf() {
            //特殊用户(source，例如敦煌，XSHOPY)根据配置调整渠道权重值
            let specialLevelConfig: any = null;
            let specialUser: any = ''
            let specailReferre: any = document.referrer || window.location.href
            specailReferre = specailReferre.toLowerCase()
            if (specailReferre.indexOf('dhgate') > -1) {
                specialUser = 'dhgate'
            } else if (specailReferre.indexOf('xshoppy') > -1) {
                specialUser = 'xshoppy'
            } else if (specailReferre.indexOf('redgle') > -1) {
                specialUser = 'redgle'
            }
            console.log('specialUser', specialUser)
            if (specialUser) {
                let specialLevelConfigFromLocal: any = localStorage.getItem('specialLevelConfig') ? JSON.parse(localStorage.getItem('specialLevelConfig') || '[]') : null
                if (!specialLevelConfigFromLocal || (!!specialLevelConfigFromLocal.expire && new Date().getTime() > specialLevelConfigFromLocal.expire)) {
                    console.log('实时获取')
                    await this.httpUtils.getSpecialLevelConfig().then((res: any) => {
                        if (!!res) {
                            specialLevelConfig = res
                            localStorage.setItem('specialLevelConfig', JSON.stringify({
                                "expire": new Date().getTime() + 10 * 60 * 1000,
                                "data": specialLevelConfig
                            }))
                            console.log('设置localstorage')
                        }
                    })
                } else {
                    specialLevelConfig = specialLevelConfigFromLocal.data
                }
            }
            console.log('specialLevelConfig', specialLevelConfig)
            return {specialUser, specialLevelConfig}
        }

        private hasTrackData(trackData: TrackData[] | null) {
            if (!trackData || trackData.length === 0) {
                return false;
            }
            let trackDatum = trackData[0];
            return trackDatum.isMerge ? (trackDatum.to && trackDatum.to.length > 0) || (trackDatum.from && trackDatum.from.length > 0) :
                trackDatum.from && trackDatum.from.length > 0;
        }

        private device() {
            const sUserAgent = navigator.userAgent.toLowerCase();
            const bIsIpad = sUserAgent.indexOf("ipad") != -1;
            const bIsIphoneOs = sUserAgent.indexOf("iphone os") != -1;
            const bIsMidp = sUserAgent.indexOf("midp") != -1;
            const bIsUc7 = sUserAgent.indexOf("rv:1.2.3.4") != -1;
            const bIsUc = sUserAgent.indexOf("ucweb") != -1;
            const bIsAndroid = sUserAgent.indexOf("android") != -1;
            const bIsCE = sUserAgent.indexOf("windows ce") != -1;
            const bIsWM = sUserAgent.indexOf("windows mobile") != -1;

            if (!(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)) {
                return "pc";
            } else {
                return "wap";
            }
        }

        private async retryGetTrack(key: string, uuid: string, retryCount: number = 1) {
            const param: any = this.handleOtherParam(retryCount)
            //console.log(document.referrer,',' ,window.location.href,document.referrer || window.location.href)
            let trackDataList = await this.httpUtils.realQueryMulti(
                {
                    tracks: [{
                        track: this.trackNumber,
                        key,
                    }],
                    uuid,
                    isChoose: this.isChoose,
                    webDateTime: Track.formatDate(),
                    referrer: this.device() === "wap" ? "###m###" + (document.referrer || window.location.href) : (document.referrer || window.location.href),
                    ...param
                }
            );
            if (!this.hasTrackData(trackDataList) && retryCount < 4) {
                // 每5秒重试机制
                setTimeout(() => {
                    this.retryGetTrack(key, uuid, ++retryCount);
                }, 3000);
                return;
            }
            this.handleTrackDateList(trackDataList);
        }

        private handleTrackDateList(trackDataList: TrackData[], isSocket: boolean = false) {
            if (trackDataList && trackDataList.length > 0) {
                let data = trackDataList[0];
                let fromList = data.from;
                if (this.trackDataList && this.trackDataList.length > 0) {
                    data.isMerge = this.trackDataList[0].isMerge;
                }
                if (fromList.length > 1) {
                    if (Date.parse(fromList[0].ondate.replaceAll("-", "/")) < Date.parse(fromList[fromList.length - 1].ondate.replaceAll("-", "/"))) {
                        fromList.reverse();
                    }
                }
                let toList = data.to;
                if (toList.length > 1) {
                    if (Date.parse(toList[0].ondate.replaceAll("-", "/")) < Date.parse(toList[toList.length - 1].ondate.replaceAll("-", "/"))) {
                        toList.reverse();
                    }
                }
                if (isSocket) {
                    if (this.trackDataList && this.trackDataList.length > 0) {
                        let trackDatum = this.trackDataList[0];
                        data.isMerge = trackDatum.isMerge;
                        if (data.mergeType === 10) {
                            data.to = trackDatum.to;
                            data.toKey = trackDatum.toKey;
                            data.toCode = trackDatum.toCode;
                        } else {
                            data.to = data.from;
                            data.toKey = data.fromKey;
                            data.toCode = data.fromCode;

                            data.from = trackDatum.from;
                            data.fromCode = trackDatum.fromCode;
                            data.fromKey = trackDatum.fromKey;

                        }
                    } else {
                        if (data.mergeType !== 10) {
                            data.to = data.from;
                            data.toKey = data.fromKey;
                            data.toCode = data.fromCode;

                            data.from = [];
                            data.fromCode = '';
                            data.fromKey = '';
                        }
                    }

                }
            }
            if (this.hasTrackData(this.trackDataList) && !isSocket) {
                return;
            }

            this.trackDataList = trackDataList;
            this.$forceUpdate();
        }

        private handleOtherParam(retryCount: number) {
            const param: any = {};
            if (retryCount === 1) {
                param.noCache = false;
            } else {
                param.cache = true;
            }
            return param;
        }

        private getKey(cargoList: Cargo[], specialUser: string, specialLevelConfig: SpecialLevelConfig) {
            let key: string = "";
            let level: number | null = null;
            for (let cargo of cargoList) {
                if (cargo.regular && cargo.regular.length > 0) {
                    cargo.regular.forEach(reg => {
                        if (new RegExp(reg).test(this.trackNumber)) {
                            console.log('满足单号规则', cargo.key)
                            // key = cargo.key;
                            let regPrivilegeLevel = this.getRegPrivilegeLevel(reg, cargo, this.trackNumber, specialUser, specialLevelConfig);
                            if (!level) {
                                level = regPrivilegeLevel;
                                key = cargo.key;
                            } else {
                                if (regPrivilegeLevel >= level) {
                                    level = regPrivilegeLevel;
                                    key = cargo.key;
                                }
                            }
                            if (localStorage.debug) {
                                console.log("规则权重结果：" + key + "； 当前渠道权重：" + level)
                            }
                        }
                    });
                }
            }
            return key;
        }

        //获取正在表达是权重
        private getRegPrivilegeLevel(reg: string, cargo: Cargo, trackNumber: string, specialUser: string, specialLevelConfig: SpecialLevelConfig) {
            //获取正则开始和结束的固定字符串
            let reg_str = reg + '';
            let start = reg_str.search(/\[|\(|\\&/);
            let end = reg_str.lastIndexOf(']');
            if (end < 1 || end < reg_str.lastIndexOf('}')) {
                end = reg_str.lastIndexOf('}');
            }
            if (end < 1 || end < reg_str.lastIndexOf(')')) {
                end = reg_str.lastIndexOf(')');
            }

            //^[0-9]{5}001200165[0-9]{6}$
            let middle = reg_str.search(/\}[A-Z0-9]+\[/);
            

            //计算固定值的其实位置和结束位置
            let match_res = trackNumber.match(reg); //匹配类似^(EE|ES)[A-Z0-9]{24}(0A|0N)$规则
            if (!match_res) {
                return 0;
            }
            let start_len = 0; //起始固定值长度
            let end_len = 0; //末尾固定值长度
            if (reg_str.indexOf('(') == 1 && match_res.length > 1) {
                start_len = match_res[1].length;
            } else {
                start_len = reg_str.substr(0, start).length - 1;
            }
            if (reg_str.lastIndexOf(')') == (reg_str.length - 2) && match_res.length > 1) {
                end_len = match_res[match_res.length - 1].length;
            } else {
                end_len = reg_str.substr(end + 1).length - 1;
            }

            //计算固定值权重值
            let fixed_level = 0; //固定值权重
            if (start_len < 1 && end_len < 1) {
                fixed_level = 1000; //1000=全模糊权重
            } else if (start_len > 1 && end_len > 1) {
                fixed_level = 3000 + start_len + end_len; //3000=两个固定值权重
            } else {
                fixed_level = 2000 + start_len + end_len; //2000=一个固定值权重
            }

            //中间有固定值的加权重
            if(middle > 0) {
                fixed_level += middle; 
            }

            // 渠道权重值（渠道类型权重值 + 优先级）
            let priority_level = 0;
            if (cargo.type == 0) {
                priority_level = 3000; //3000=邮政固定权重值
            } else {
                priority_level = 1000; //1000=国际跨境或国内跨境权重值
            }
            if (!!cargo.priority_level && cargo.priority_level > 0 && cargo.priority_level < 1000) { // 优先级越小越高(超过1000，按0处理)
                priority_level = priority_level + (1000 - cargo.priority_level);
            }

            if (!!specialLevelConfig && !!specialLevelConfig[specialUser] && !!specialLevelConfig[specialUser][cargo.id]) {
                priority_level += parseInt(specialLevelConfig[specialUser][cargo.id])
            }

            return parseInt(fixed_level + '' + priority_level);
        }


        private static formatDate() {
            let date = new Date();
            let month: string | number = date.getMonth() + 1;
            let strDate: string | number = date.getDate();
            let hour = date.getHours() > 9 ? date.getHours() : "0" + date.getHours();
            let minute = date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes();
            let second = date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds();
            let millisecond = date.getMilliseconds() > 99 ? date.getMilliseconds() : (date.getMilliseconds() < 10 ?
                "00" + date.getMilliseconds() : "0" + date.getMilliseconds());
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = "0" + strDate;
            }
            return date.getFullYear() + "-" + month + "-" + strDate +
                " " + hour + ":" + minute +
                ":" + second + " " + millisecond;
        }

        private getFromCode() {
            if (!this.trackDataList) {
                return "";
            }
            if (this.trackDataList!.length === 0) {
                return "";
            }
            return this.trackDataList![0].fromCode || "";
        }

        private getToCode() {
            if (!this.trackDataList) {
                return "";
            }
            if (this.trackDataList!.length === 0) {
                return "";
            }
            return this.trackDataList![0].toCode || "";
        }

        private getCarrir(key: keyof TrackData = 'fromKey') {
            if (!this.trackDataList) {
                return "";
            }
            if (this.trackDataList!.length === 0) {
                return "";
            }
            let cargos = this.cargo!.filter(o => o.key === this.trackDataList![0][key]);
            if (cargos.length === 0) {
                return "";
            }
            return cargos[0].en_name;
        }

        //判断物流商的类型
        private isShipType(shipType: number) {
            if (!this.trackDataList) {
                return "";
            }
            if (this.trackDataList!.length === 0) {
                return "";
            }
            let cargos = this.cargo!.filter(o => o.key === this.trackDataList![0]['fromKey']);
            if (cargos.length === 0) {
                return "";
            }
            return cargos[0].shipType == shipType;
        }

        /**
         * 运输状态
         */
        private getTrackStatus() {
            if (!this.trackDataList) {
                return this.getLanguage("Searching");
            }
            if (this.trackDataList!.length === 0) {
                return this.getLanguage("NotFound");
            }
            console.log(this.trackDataList);
            switch (this.trackDataList![0].result) {
                case 40:
                    return this.getLanguage("Delivered");
                case 50:
                case 25:
                case 35:
                    return this.getLanguage("Alert");
                case 0:
                case 2:
                    return this.getLanguage("NotFound");
                case 45:
                case 55:
                case 10:
                case 11:
                case 12:
                case 13:
                case 8:
                    return this.getLanguage("InTransit");
                case 20:
                    return this.getLanguage("Undelivered");
                case 5:
                case 65:
                case 15:
                    return this.getLanguage("Returned");
                case 30:
                    return this.getLanguage("PickUp");
                default :
                    return this.getLanguage("NotFound");
            }
        }

        private getLanguage(key: string) {
            return this.$t(key, this.language.locale);
        }

        private async selectLanguage(countryItem: { text: string, locale: string }) {
            if (!this.trackDataList || this.trackDataList.length === 0 || this.trackDataList[0].from.length === 0) {
                return 0;
            }
            const item = this.trackDataList[0];
            const searchParamFrom: { expKey: string, toLang: string, tracks: Status[] } = {
                expKey: item.fromKey || "",
                toLang: countryItem.locale,
                tracks: item.from
            };
            searchParamFrom.expKey = item.fromKey || "";
            searchParamFrom.toLang = countryItem.locale;
            const bak = JSON.stringify(this.trackDataList);
            this.trackDataList = null;
            try {
                const fromData = await this.httpUtils.translate(searchParamFrom);
                this.trackDataList = JSON.parse(bak);
                this.trackDataList![0].from = fromData;
            } catch (e) {
                console.error(e);
            }
        }

        private getCountry(country: any) {
            let transfromCityCnElement = transfromCityCn[country];
            if (!transfromCityCnElement) {
                return country;
            }
            const china = ["zh-CN", "zh-hk"];
            return china.indexOf(this.language.locale) !== -1 ? transfromCityCnElement : country;
        }

        private showMore() {
            this.$emit('more', (<HTMLElement>this.$refs['timeLineBox']).offsetHeight + (<HTMLElement>this.$refs['trackHeader']).offsetHeight);
        }

        private ws!: WebSocket;

        private webSocketStart(url: string) {
            this.ws = new WebSocket(url);
            this.ws.onopen = () => {
                console.log('websocket开启成功');
                this.ws.send(this.uuid);
            };
            this.ws.onmessage = (evt) => {
                let datum = JSON.parse(evt.data).data;
                this.handleTrackDateList(datum, true);
            };
            this.ws.onclose = () => {
                this.webSocketStart(url)
            };
            this.ws.onerror = (err) => {
                console.log('Socket encountered error');
                this.ws.close();
            };

        }

        /**
         * @description 打开订阅邮件弹窗
         */
        private openDialogEmail() {
            this.dialogEmailVisible = true;
            this.emailInput = [
                {value: ''}
            ];
        }

        /**
         * @description 添加邮箱
         */
        private dialogEmailAdd() {
            if (this.emailInput.length < 3) {
                 this.emailInput.push({value: ''});
            }
        }

        /**
         * @description 删除邮箱
         */
        private dialogEmailDel(emailIndex: number) {
            if (this.emailInput.length > 1) {
                 this.emailInput.splice(emailIndex, 1);
            }
        }

        /**
         * @description 邮件订阅提交
         */
        private dialogEmailConfirm() {
            let reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
            let emailValArr = [];
            let emailValFlag = true;
            let isCn = this.language.locale == 'zh-CN' || this.language.locale == 'zh-hk';
			for (let i = 0, len = this.emailInput.length; i < len; i++) {
				let val = this.emailInput[i].value
				if (!val) {
					emailValFlag = false
                    this.$message.error(isCn ? `第${i + 1}行不能为空！` : `Line ${i + 1} cannot be empty!`)
                    break;
				} else if (!reg.test(val)) {
					emailValFlag = false
                    this.$message.error(isCn ? `第${i + 1}行邮件格式有误！` : `Line ${i + 1}  is not in mail format!`)
                    break;
				} else {
					emailValArr.push(val)
				}
            }
            
            //表单提交
            if (emailValArr.length > 0 && emailValFlag && this.trackDataList && this.trackDataList.length > 0) {
				let noticeParam = {
					"trackNumber": this.trackDataList[0].track,
                    "logiskey": this.trackDataList[0].fromKey,
                    "source": 'dhgate',
					"email": emailValArr
                };
                this.httpUtils.postSubscribeTrack(noticeParam).then((result: any) => {
                    if (result) {
                        this.$message.success(this.getLanguage("AddSubscribedTips1"));
                        this.dialogEmailVisible = false;
                    } else {
                        this.$message.error(this.getLanguage("AddSubscribedTips2"));
                    }
                });
            }
        }
        
    }
