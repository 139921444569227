export const language = {
    trackOrderStatus: {
        'zh-CN': '跟踪订单状态',
        'en': 'Track Order Status',
        'zh-hk': '跟踪訂單狀態',
        'kr': '주문 조회',
        'de': 'Bestellung verfolgen',
        'es': 'Realiza el seguimiento de tu pedido',
        'pt': 'Acompanhar o estado do pedido',
        'fr': 'Suivez votre commande',
        'ru': 'Отследить',
    },
    trackingNumber: {
        'zh-CN': '快递单号',
        'en': 'Tracking Number',
        'zh-hk': '快遞單號',
        'kr': '추적 번호',
        'de': 'Sendungsummer',
        'es': 'el número de rastreo',
        'pt': 'Numero de rastreio',
        'fr': 'Numéro de suivi',
        'ru': 'Номер Отслеживания',
    },
    track: {
        'zh-CN': '查询',
        'en': 'Track',
        'zh-hk': '查詢',
        'kr': '주문 조회',
        'de': 'Bestellung verfolgen',
        'es': 'Buscar',
        'pt': 'Pesquisa',
        'fr': 'Chercher',
        'ru': 'Отслеживать',
    },
    inputTackNumber: {
        'zh-CN': '请输入您的运单号',
        'en': 'Please input the tracking number',
        'zh-hk': '請輸入您的運單號',
        'kr': '트래킹 번호를 입력하세요',
        'de': 'Geben Sie die Tracking-Nummer ein',
        'es': 'Introduce el número de seguimiento',
        'pt': 'Digite o numero de rastreio',
        'fr': 'Saisissez le numéro de suivi',
        'ru': 'Введите номер отслеживания',
    },
    Logistics: {
        'zh-CN': '物流商',
        'en': 'Logistics',
        'zh-hk': '物流商',
        'kr': '후방 근무',
        'de': 'Logistik',
        'es': 'Logisticas',
        'pt': 'Logística',
        'fr': 'Logistique',
        'ru': 'Logistics',
    },
    AutoDetectLogistics: {
        'zh-CN': '自动识别物流商',
        'en': 'Auto Detect Logistics',
        'zh-hk': '自動識別物流商',
        'kr': '자동 검색 물류',
        'de': 'Auto Detect Logistics',
        'es': 'Auto Detect Logistics',
        'pt': 'Auto Detect Logistics',
        'fr': 'Auto Detect Logistics',
        'ru': 'Автоопределение логистики',
    },
    Delivered: {
        'zh-CN': '已签收',
        'en': 'Delivered',
        'zh-hk': '已簽收',
        'kr': '교부 하 다.',
        'de': 'Delivered',
        'es': 'Delivered',
        'pt': 'Delivered',
        'fr': 'Delivered',
        'ru': 'Delivered',
    },
    Alert: {
        'zh-CN': '可能异常',
        'en': 'Alert',
        'zh-hk': '可能異常',
        'kr': '경각성 적',
        'de': 'Alarm',
        'es': 'Alerta',
        'pt': 'Alerta',
        'fr': 'Alerte',
        'ru': 'Предупреждение',
    },
    NotFound: {
        'zh-CN': '查询不到',
        'en': 'Not Found',
        'zh-hk': '查詢不到',
        'kr': '찾 을 수 없다',
        'de': 'Nicht gefunden',
        'es': 'No encontrado',
        'pt': 'Não encontrado',
        'fr': 'Pas trouvé',
        'ru': 'Не обнаружена',
    },
    InTransit: {
        'zh-CN': '运输途中',
        'en': 'In Transit',
        'zh-hk': '運輸途中',
        'kr': '도중에',
        'de': 'Versand',
        'es': 'En tránsito',
        'pt': 'Em trânsito',
        'fr': 'En transit',
        'ru': 'В пути',
    },
    Undelivered: {
        'zh-CN': '投递失败',
        'en': 'Undelivered',
        'zh-hk': '投遞失敗',
        'kr': '배달 되 지 않 음',
        'de': 'Nicht geliefert',
        'es': 'No entregado',
        'pt': 'Não entregue',
        'fr': 'non remis',
        'ru': 'Не успешно доставлен',
    },
    Returned: {
        'zh-CN': '包裹退回',
        'en': 'Returned',
        'zh-hk': '包裹退回',
        'kr': '돌아가다',
        'de': 'Returned',
        'es': 'Returned',
        'pt': 'Returned',
        'fr': 'Returned',
        'ru': 'Возврат',
    },
    PickUp: {
        'zh-CN': '到达待取',
        'en': 'Pick Up',
        'zh-hk': '到達待取',
        'kr': '들다',
        'de': 'Abholen',
        'es': 'Recoger el paquete',
        'pt': 'Pegar',
        'fr': 'Récupéré',
        'ru': 'Ожидается получение',
    },
    Day: {
        'zh-CN': '天',
        'en': 'Day',
        'zh-hk': '天',
        'kr': '하늘.',
        'de': 'Day',
        'es': 'Day',
        'pt': 'Day',
        'fr': 'Day',
        'ru': 'Day',
    },
    Translate: {
        'zh-CN': '轨迹翻译',
        'en': 'Translate',
        'zh-hk': '軌跡翻譯',
        'kr': '검색 필요.',
        'de': 'Übersetzen',
        'es': 'Traducir',
        'pt': 'Traduzir',
        'fr': 'traduire',
        'ru': 'Translate',
    },
    InputLogistics: {
        'zh-CN': '选择运输商',
        'en': 'Select Carrier',
        'zh-hk': '選擇運輸商',
        'kr': '운송 업 체 를 선택 하 다',
        'de': 'Select Carrier',
        'es': 'Select Carrier',
        'pt': 'Select Carrier',
        'fr': 'Select Carrier',
        'ru': 'Select Carrier',
    },
    To: {
        'zh-CN': '到',
        'en': 'To',
        'zh-hk': '到',
        'kr': '도착 하 다.',
        'de': 'Auf',
        'es': 'A',
        'pt': 'Para',
        'fr': 'À',
        'ru': 'To',
    },
    From: {
        'zh-CN': '从',
        'en': 'From',
        'zh-hk': '從',
        'kr': '부터.',
        'de': 'Aus',
        'es': 'De',
        'pt': 'De',
        'fr': 'De',
        'ru': 'From',
    },
    Searching: {
        'zh-CN': '查询中',
        'en': 'Inquires',
        'zh-hk': '查詢中',
        'kr': '문의 하 다..',
        'de': 'Erkundigt',
        'es': 'Consultas',
        'pt': 'Inquires',
        'fr': 'Inquires',
        'ru': 'Inquires',
    },
    SubscribeEmail:{
        "zh-CN":"免费订阅邮件",
        "zh-hk":"免費訂閱郵件",
        "ru":"Get Email Updates",
        "en":"Get Email Updates",
        "kr":"Get Email Updates",
        "de":"Get Email Updates",
        "es":"Get Email Updates",
        "fr":"Get Email Updates",
        "pt":"Get Email Updates",
        "ja":"Get Email Updates"
    },
    SubscribeEmailNotice:{
        "zh-CN":"订阅邮件通知",
        "zh-hk":"訂閱郵件通知",
        "ru":"Get email update",
        "en":"Get email update",
        "kr":"Get email update",
        "de":"Get email update",
        "es":"Get email update",
        "fr":"Get email update",
        "pt":"Get email update",
        "ja":"Get email update"
    },
    EmailTips: {
        "zh-CN":"请输入有效的电子邮箱！",
        "zh-hk":"請輸入有效的電子郵箱！",
        "ru":"Please enter your valid email!",
        "en":"Please enter your valid email!",
        "kr":"유효한 이메일 을 입력 하 세 요!",
        "de":"Bitte geben Sie Ihre gültige E-Mail ein!",
        "es":"Por favor ingrese su correo valido!",
        "fr":"Merci d'entrer un email valide!",
        "pt":"Por favor insira o seu email válido!",
        "ja":"有効なEメールを入力してください！"
    },
    Delete: {
        "zh-CN":"删除",
        "zh-hk":"刪除",
        "ru":"Delete",
        "en":"Delete",
        "kr":"삭제 하 다.",
        "de":"Löschen",
        "es":"Borrar",
        "fr":"Effacer",
        "pt":"Excluir",
        "ja":"削除"
    },
    Cancel: {
        "zh-CN":"取消",
        "zh-hk":"取消",
        "ru":"Cancel",
        "en":"Cancel",
        "kr":"취소 하 다.",
        "de":"Stornieren",
        "es":"Cancelar",
        "fr":"Annuler",
        "pt":"Cancelar",
        "ja":"キャンセル"
    },
    emailConfirm: {
        "zh-CN":"确认",
        "zh-hk":"確認",
        "ru":"подтверждать",
        "en":"confirm",
        "kr":"확인하다",
        "de":"Bestätigen",
        "es":"confirmar",
        "fr":"confirmer",
        "pt":"confirme",
        "ja":"かくにん"
    },
    AddAnotherEmail: {
        "zh-CN":"增加收件人邮箱",
        "zh-hk":"增加收件人郵箱",
        "ru":"Добавить еще один адрес электронной почты",
        "en":"Add another email",
        "kr":"다른 이메일 추가",
        "de":"Fügen Sie eine weitere E-Mail hinzu",
        "es":"Agregar otro correo electrónico",
        "fr":"Ajouter un autre e-mail",
        "pt":"Adicionar outro e-mail",
        "ja":"追加の電子メールの追加"
    },
    SubscribedTips: {
        "zh-CN":"一旦您成功订阅后，当您的包裹状态发生变化时，DHgate.com会及时的发送电子邮件给您。",
        "zh-hk":"一旦您成功訂閱後，當您的包裹狀態發生變化時，DHgate.com會及時的傳送電子郵件給您。",
        "ru":"Once you have subscribed successfully, DHgate.com will send an email to you when the parcel's status changes.",
        "en":"Once you have subscribed successfully, DHgate.com will send an email to you when the parcel's status changes.",
        "kr":"가입 에 성공 하면 Track 718 은 가방 상태 가 변 경 될 때 이메일 을 보 냅 니 다.",
        "de":"Sobald Sie sich erfolgreich angemeldet haben, sendet DHgate.com eine E-Mail an Sie, wenn sich der Status des Pakets ändert.",
        "es":"Una vez que se haya suscrito correctamente, DHgate.com le enviará un correo electrónico cuando cambie el estado del paquete.",
        "fr":"Une fois que vous vous êtes abonné avec succès, DHgate.com vous enverra un courrier électronique lorsque le statut du colis changera.",
        "pt":"UMA vez que você assinou com SUCESSO, DHgate.com irá enviar um e - mail para você Quando a parcela Da mudança de status.",
        "ja":"登録に成功すると、ラップのステータスが変更されると、DHgate.com はEメールを送信します。"
    },
    AddSubscribedTips1: {
        "zh-CN":"添加订阅成功",
        "zh-hk":"新增訂閱成功",
        "ru":"Add successfully",
        "en":"Add successfully",
        "kr":"추가 성공",
        "de":"noch erfolgreich",
        "es":"Añadir con éxito",
        "fr":"ajouter avec succès",
        "pt":"Adicionar com SUCESSO",
        "ja":"追加成功"
    },
    AddSubscribedTips2: {
        "zh-CN":"订阅失败",
        "zh-hk":"訂閱失敗",
        "ru":"Subscription failed",
        "en":"Subscription failed",
        "kr":"구독 실패",
        "de":"Abonnement fehlgeschlagen",
        "es":"Subscripción fallida",
        "fr":"L'abonnement a échoué",
        "pt":"Inscrição falhou",
        "ja":"購読に失敗しました"
    },
};

export const trackLanguage = [
    {name: 'English', locale: 'en'},
    {name: 'Spanish', locale: 'es'},
    {name: 'German', locale: 'de'},
    {name: 'French', locale: 'fr'},
    {name: 'Portuguese', locale: 'pt'},
    {name: 'Arabic', locale: 'ar'},
    {name: 'Chinese Simplified', locale: 'zh-cn'},
    {name: 'Chinese Traditional', locale: 'zh-tw'},
    {name: 'Hmong', locale: 'hmn'},
    {name: 'Kurdish (Kurmanji)', locale: 'ku'},
    {name: 'Nepali', locale: 'ne'},
    {name: 'Myanmar (Burmese)', locale: 'my'},
    {name: 'Vietnamese', locale: 'vi'},
    {name: 'Albanian', locale: 'sq'},
    {name: 'Belarusian', locale: 'be'},
    {name: 'Bulgarian', locale: 'bg'},
    {name: 'Croatian', locale: 'hr'},
    {name: 'Czech', locale: 'cs'},
    {name: 'Danish', locale: 'da'},
    {name: 'Dutch', locale: 'nl'},
    {name: 'Esperanto', locale: 'eo'},
    {name: 'Estonian', locale: 'et'},
    {name: 'Filipino', locale: 'tl'},
    {name: 'Finnish', locale: 'fi'},
    {name: 'Greek', locale: 'el'},
    {name: 'Hebrew', locale: 'iw'},
    {name: 'Hindi', locale: 'hi'},
    {name: 'Hungarian', locale: 'hu'},
    {name: 'Icelandic', locale: 'is'},
    {name: 'Indonesian', locale: 'id'},
    {name: 'Irish', locale: 'ga'},
    {name: 'Italian', locale: 'it'},
    {name: 'Japanese', locale: 'ja'},
    {name: 'Javanese', locale: 'jw'},
    {name: 'Kazakh', locale: 'kk'},
    {name: 'Korean', locale: 'ko'},
    {name: 'Latin', locale: 'la'},
    {name: 'Latvian', locale: 'lv'},
    {name: 'Lithuanian', locale: 'lt'},
    {name: 'Luxembourgish', locale: 'lb'},
    {name: 'Malay', locale: 'ms'},
    {name: 'Malayalam', locale: 'ml'},
    {name: 'Norwegian', locale: 'no'},
    {name: 'Persian', locale: 'fa'},
    {name: 'Polish', locale: 'pl'},
    {name: 'Romanian', locale: 'ro'},
    {name: 'Russian', locale: 'ru'},
    {name: 'Serbian', locale: 'sr'},
    {name: 'Slovak', locale: 'sk'},
    {name: 'Slovenian', locale: 'sl'},
    {name: 'Swedish', locale: 'sv'},
    {name: 'Thai', locale: 'th'},
    {name: 'Turkish', locale: 'tr'},
    {name: 'Ukrainian', locale: 'uk'},
    {name: 'Welsh', locale: 'cy'}
]
