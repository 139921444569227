const CryptoJS = require('crypto-js');

export class Sign {
    private key = '482737d7ad3f29a2b43e73345830385a';
    private iv = 'TRACK718-QWER788';
    protected static sign: string;

    constructor() {
        Sign.sign = this.getSign();
    }

    private getSign() {
        let oIv = this.getIv()
        let okey = this.getKey()
        return `(function(word){
        function Decrypt(data, key, iv) {
            let decrypt = CryptoJS.AES.decrypt(data, key, {iv: iv, mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
            return CryptoJS.enc.Utf8.stringify(decrypt).toString()
        }
        function formatkey(key) {
            key = key + '';
            return CryptoJS.enc.Utf8.parse(key.padEnd(16, 'ABDEFMN'));
        }
        function Encrypt(data, key, iv) {
            let srcs = CryptoJS.enc.Utf8.parse(data);
            let encrypted = CryptoJS.AES.encrypt(srcs, key, {iv: iv, mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
            return encrypted.toString();
        }
        ${oIv.vals}${okey.vals}${oIv.text}${okey.text}return CryptoJS.MD5(Encrypt(word, key, iv)).toString();})('$data');`;
    }
    private getIv() {
        let fns = [];
        let text = '';
        let vals = '';
        let arr = this.iv.split('');
        for (let i = 0; i < arr.length; i++) {
            const obj = this.create(arr[i], i+1);
            vals += `const key${obj.i} = "${obj.key}";const iv${obj.i} = "${obj.iv}";const data${obj.i} = "${obj.data}";`
            text += `function get${obj.i}(data){return Decrypt(data, formatkey(key${obj.i}), CryptoJS.enc.Utf8.parse(iv${obj.i}));};`;
            fns.push(`get${obj.i}(data${obj.i})`)
        }
        vals += `const iv = getIv();`;
        text += `function getIv(){return CryptoJS.enc.Utf8.parse(${fns.join('+')});}`
        return {vals, text};
    }
    private getKey() {
        let fns = [];
        let text = '';
        let vals = '';
        let arr = this.key.split('');
        for (let i = 0; i < arr.length; i++) {
            const obj = this.create(arr[i], i+1);
            vals += `const key2${obj.i} = "${obj.key}";const iv2${obj.i} = "${obj.iv}";const data2${obj.i} = "${obj.data}";`
            text += `function get2${obj.i}(data){return Decrypt(data, formatkey(key2${obj.i}), CryptoJS.enc.Utf8.parse(iv2${obj.i}));};`;
            fns.push(`get2${obj.i}(data2${obj.i})`)
        }
        vals += `const key = getKey();`;
        text += `function getKey(){return CryptoJS.enc.Utf8.parse(${fns.join('+')});}`
        return {vals, text};
    }

    private create(k: string, i: number) {
        const time = String(Date.now());
        const key = time.substring(time.length-3);
        const iv = Date.now(); //秘钥偏移量
        const data = this.Encrypt(k, this.formatkey(key), CryptoJS.enc.Utf8.parse(iv));
        return {key,iv,data,i};
    }

    private Encrypt(data: any, key: string, iv: string) {
        let srcs = CryptoJS.enc.Utf8.parse(data);
        let encrypted = CryptoJS.AES.encrypt(srcs, key, {iv: iv, mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return encrypted.toString();
    }
    private Decrypt(data: any, key: string, iv: string) {
        let decrypt = CryptoJS.AES.decrypt(data, key, {iv: iv, mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString()
    }

    private formatkey(key: string) {
        key = key + '';
        return CryptoJS.enc.Utf8.parse(key.padEnd(16, 'ABDEFMN'));
    };
}
