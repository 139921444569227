export const transfromCityCn: any = {
    "AD": "安道尔",
    "AE": "阿联酋",
    "AF": "阿富汗",
    "AG": "安提瓜及巴布达",
    "AI": "安圭拉岛",
    "AL": "阿尔巴尼亚",
    "AM": "亚美尼亚",
    "AN": "荷属安的列斯群岛",
    "AO": "安哥拉",
    "AR": "阿根廷",
    "AS": "美属萨摩亚群岛",
    "AT": "奥地利",
    "AU": "澳大利亚",
    "AW": "阿鲁巴岛",
    "AZ": "阿塞拜疆(独联体)",
    "BA": "波斯尼亚-黑塞哥维那共和国",
    "BB": "巴巴多斯",
    "BD": "孟加拉国",
    "BE": "比利时",
    "BF": "布基纳法索",
    "BG": "保加利亚",
    "BH": "巴林",
    "BI": "布隆迪",
    "BJ": "贝宁",
    "BM": "百慕大",
    "BN": "文莱",
    "BO": "波利维亚",
    "BR": "巴西",
    "BS": "巴哈马",
    "BT": "不丹",
    "BV": "布维岛",
    "BW": "博茨瓦纳",
    "BY": "白俄罗斯(独联体)",
    "BZ": "伯利兹",
    "CA": "加拿大",
    "CC": "科科斯群岛",
    "CD": "刚果民主共和国",
    "CF": "中非共和国",
    "CG": "刚果",
    "CH": "瑞士",
    "CI": "科特迪瓦(象牙海岸)",
    "CK": "库克群岛",
    "CL": "智利",
    "CM": "喀麦隆",
    "CN": "中国",
    "CO": "哥伦比亚",
    "CR": "哥斯达黎加",
    "CU": "古巴",
    "CV": "佛得角群岛",
    "CX": "圣诞岛",
    "CY": "塞浦路斯",
    "CZ": "捷克共和国",
    "DE": "德国",
    "DJ": "吉布提",
    "DK": "丹麦",
    "DM": "多米尼克",
    "DO": "多米尼加共合国",
    "DZ": "阿尔及利亚",
    "EC": "厄瓜多尔",
    "EE": "爱沙尼亚",
    "EG": "埃及",
    "EH": "西撒哈拉",
    "ER": "厄里特立亚",
    "ES": "西班牙",
    "ET": "埃塞俄比亚",
    "FI": "芬兰",
    "FJ": "斐济",
    "FK": "福克兰群岛",
    "FM": "密克罗尼西亚",
    "FO": "法鲁群岛",
    "FR": "法国",
    "GA": "加蓬",
    "GB": "英国",
    "GD": "格林纳达",
    "GE": "格鲁吉亚",
    "GF": "法属圭亚那",
    "GG": "根西岛",
    "GH": "加纳",
    "GI": "直布罗陀",
    "GL": "格陵兰",
    "GM": "冈比亚",
    "GN": "几内亚",
    "GP": "瓜德罗普",
    "GQ": "赤道几内亚",
    "GR": "希腊",
    "GS": "南乔治亚岛和南桑威奇群岛",
    "GT": "危地马拉",
    "GU": "关岛",
    "GW": "几内亚比绍",
    "GY": "圭亚那",
    "HK": "香港",
    "HM": "赫德岛和麦克唐纳岛",
    "HN": "洪都拉斯",
    "HR": "克罗地亚",
    "HT": "海地",
    "HU": "匈牙利",
    "IC": "加那利群岛",
    "ID": "印度尼西亚",
    "IE": "爱尔兰",
    "IL": "以色列",
    "IN": "印度",
    "IO": "英属印度洋地区(查各群岛)",
    "IQ": "伊拉克",
    "IR": "伊朗",
    "IS": "冰岛",
    "IT": "意大利",
    "JE": "泽西岛(英属)",
    "JM": "牙买加",
    "JO": "约旦",
    "JP": "日本",
    "JU": "南斯拉夫",
    "KE": "肯尼亚",
    "KG": "吉尔吉斯斯坦",
    "KH": "柬埔寨",
    "KI": "基利巴斯共和国",
    "KM": "科摩罗",
    "KN": "圣基茨",
    "KP": "朝鲜",
    "KR": "韩国",
    "KV": "科索沃",
    "KW": "科威特",
    "KY": "开曼群岛",
    "KZ": "哈萨克斯坦",
    "LA": "老挝",
    "LB": "黎巴嫩",
    "LC": "圣卢西亚",
    "LI": "列支敦士登",
    "LK": "斯里兰卡",
    "LR": "利比里亚",
    "LS": "莱索托",
    "LT": "立陶宛",
    "LU": "卢森堡",
    "LV": "拉脱维亚",
    "LY": "利比亚",
    "MA": "摩洛哥",
    "MC": "摩纳哥",
    "MD": "摩尔多瓦",
    "ME": "黑山共和国",
    "MG": "马达加斯加",
    "MH": "马绍尔群岛",
    "MK": "马其顿",
    "ML": "马里",
    "MM": "缅甸",
    "MN": "蒙古",
    "MO": "澳门",
    "SP": "塞班岛",
    "MQ": "马提尼克岛",
    "MR": "毛里塔尼亚",
    "MS": "蒙特塞拉岛",
    "MT": "马尔他",
    "MU": "毛里求斯",
    "MV": "马尔代夫",
    "MW": "马拉维",
    "MX": "墨西哥",
    "MY": "马来西亚",
    "MZ": "莫桑比克",
    "NA": "纳米比亚",
    "NC": "新喀里多尼亚",
    "NE": "尼日尔",
    "NF": "诺褔克岛",
    "NG": "尼日利亚",
    "NI": "尼加拉瓜",
    "NL": "荷兰",
    "NO": "挪威",
    "NP": "尼泊尔",
    "NR": "瑙鲁共和国",
    "NU": "纽埃岛",
    "NZ": "新西兰",
    "OM": "阿曼",
    "PA": "巴拿马",
    "PE": "秘鲁",
    "PF": "塔希堤岛(法属波利尼西亚)",
    "PG": "巴布亚新几内亚",
    "PH": "菲律宾",
    "PK": "巴基斯坦",
    "PL": "波兰",
    "PM": "圣皮埃尔和密克隆群岛",
    "PN": "皮特凯恩群岛",
    "PR": "波多黎各",
    "PT": "葡萄牙",
    "PW": "帕劳",
    "PY": "巴拉圭",
    "QA": "卡塔尔",
    "RE": "留尼汪岛",
    "RO": "罗马尼亚",
    "RS": "塞尔维亚共和国",
    "RU": "俄罗斯",
    "RW": "卢旺达",
    "SA": "沙特阿拉伯",
    "SB": "所罗门群岛",
    "SC": "塞舌尔",
    "SD": "苏丹",
    "SE": "瑞典",
    "SG": "新加坡",
    "SH": "圣赫勒拿岛",
    "SI": "斯洛文尼亚",
    "SJ": "斯瓦尔巴岛和扬马延岛",
    "SK": "斯洛伐克共和国",
    "SL": "塞拉里昂",
    "SM": "圣马力诺",
    "SN": "塞内加尔",
    "SO": "索马里",
    "SR": "苏里南",
    "ST": "圣多美和普林西比",
    "SV": "萨尔瓦多",
    "SY": "叙利亚",
    "SZ": "斯威士兰",
    "TA": "特里斯坦",
    "TC": "特克斯和凯科斯群岛",
    "TD": "乍得",
    "TF": "法属南部领土",
    "TG": "多哥",
    "TH": "泰国",
    "TJ": "塔吉克斯坦",
    "TK": "托克劳",
    "TL": "东帝汶",
    "TM": "土库曼斯坦",
    "TN": "突尼斯",
    "TO": "汤加",
    "TR": "土耳其",
    "TT": "特立尼达和多巴哥",
    "TV": "图瓦卢",
    "TW": "台湾",
    "TZ": "坦桑尼亚",
    "UA": "乌克兰",
    "UG": "乌干达",
    "UM": "美国本土外小岛屿",
    "US": "美国",
    "UY": "乌拉圭",
    "UZ": "乌兹别克斯坦",
    "VA": "梵蒂冈",
    "VC": "圣文森特和格林纳丁斯岛",
    "VE": "委内瑞拉",
    "VG": "英属维尔京群岛",
    "VI": "美属维尔京群岛",
    "VN": "越南",
    "VU": "瓦努阿图",
    "WF": "瓦利斯群岛和富图纳群岛",
    "WS": "西萨摩亚",
    "XB": "伯奈尔岛",
    "XC": "库拉索岛(荷兰)",
    "XD": "阿森松",
    "XE": "圣尤斯塔提马斯岛",
    "XG": "北非西班牙属土",
    "XH": "亚速尔群岛",
    "XI": "马德拉岛",
    "XJ": "巴利阿里群岛",
    "XK": "加罗林群岛",
    "XM": "圣马腾岛",
    "XN": "尼维斯岛",
    "XS": "索马里共和国",
    "XY": "圣巴特勒米岛",
    "YE": "也门阿拉伯共合国",
    "YT": "马约特",
    "ZA": "南非",
    "ZM": "赞比亚",
    "ZR": "扎伊尔",
    "ZW": "津巴布韦",
    "MF": "圣马丁岛",
    "IM": "马恩岛",
    "AX": "阿兰群岛",
    "FP": "法属波利尼西亚",
    "SS": "南苏丹"
}
