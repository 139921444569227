import axios, {AxiosInstance} from "axios"
import {Sign} from "@/js/Sign";
const CryptoJS = require('crypto-js');
const Buffer = require('buffer').Buffer
const zlib = require('zlib')

export interface Cargo{
    cacheTime: string;
    code: string;
    en_name: string;
    icon: string;
    id: number;
    priority_level: number;
    key: string;
    name: string;
    otherKey: string;
    regular: string[];
    shipType: number;
    type: number;
    timeZone: string;
    url: string;
}

export interface TrackData {
    expect: {};
    frist: [];
    from: Status[];
    fromCode: string;
    fromKey: string;
    isMerge: boolean;
    latest: Status;
    logisId: number;
    online: Status;
    origin: string;
    result: number;
    sign: Status;
    to: Status[];
    toCode: string;
    toKey: string;
    track: string;
    uuid: string;
    mergeType: number;
    wms: [];
}
export interface Status {
    address: string;
    ondate: string;
    result: number;
    resultDate: string;
    status: string;
}

export interface Store {
    numFound: number;
    prods: Prods[];
}
export interface Prods {
    image: string;
    title: string;
    price: string;
    originalPrice: string;
    promotionDetailUrl: string;
    unitHighestPrice: string;
    unitLowestPrice: string;
    unitPromoHighestPrice: string;
    unitPromoLowestPrice: string;
    origalCurrencySign: string;
    origalSimplifyCurrencySign: string;
    discountRate: string;
    couponReduction: string;
    reduction: string;
    full: string;
}

export class HttpUtils extends Sign {
    public realQueryMulti(param: any): Promise<TrackData[]> {
        return this.post('https://apigetway.track718.net/track/real_query_multi', param, 3);
    }
    public cargo(): Promise<Cargo[]>{
        return this.post('https://apigetway.track718.net/track/cargo', null);
    }
    public translate(param: any): Promise <Status[]> {
        return new Promise((resolve, reject) => {
            axios.post('https://apigetway.track718.net/track/translate', param, {
                headers: {
                    'Track718-API-Sign': eval(Sign.sign.replace('$data', JSON.stringify(param)))
                }
            }).then(res => {
                if (res.data.status === 1) {
                    resolve(res.data.tracks);
                } else {
                    reject(res.data.msg || '请求失败')
                }
            }).catch(res => {
                if (!res || !res.data) {
                    res = {
                        data: {
                            msg: '请求失败'
                        }
                    }
                }
                reject(res.data);
            })
        })
    }

    public getStore(param: any): Promise <Store> {
        return new Promise((resolve, reject) => {
            axios.get('https://adcenter.dhgate.com/adapi/linkAd', {
                params : param
            }).then(res => {
                if (res.data.result.code === "200") {
                    resolve(res.data.result);
                } else {
                    reject(res.data.msg || '请求失败')
                }
            }).catch(res => {
                if (!res || !res.data) {
                    res = {
                        data: {
                            msg: '请求失败'
                        }
                    }
                }
                reject(res.data);
            })
        })
    }

    public async getCargo() {
        let cargo = JSON.parse(sessionStorage.getItem("cargo")!);
        if (!cargo) {
            cargo = await this.cargo();
            sessionStorage.setItem("cargo", JSON.stringify(cargo))
        }
        
        for (let i = 0, len = cargo.length; i < len; i++) {
            try {
                if (typeof cargo[i].regular === 'string') {
                    cargo[i].regular = JSON.parse(zlib.unzipSync(Buffer.from(cargo[i].regular, 'base64')).toString());
                }
            } catch (error) {
                cargo[i].regular = [];
            }
        }
        return cargo;
    }

    private  post<T>(url:string, param: any, successCode: number = 0): Promise <T> {
        return new Promise((resolve, reject) => {
            axios.post(url, param, {
                headers: {
                    "Track718-API-Sign": eval(Sign.sign.replace("$data", JSON.stringify(param)))
                }
            }).then(res => {
                if (res.data.status.code === successCode || res.data.status.code == 0) {
                    resolve(res.data.data);
                } else {
                    reject(res.data || '请求失败')
                }
            }).catch(res => {
                if (!res || !res.data) {
                    res = {
                        data: {
                            status: {
                                msg: '请求失败'
                            }
                        }
                    }
                }
                reject(res.data);
            })
        })
    }

    public getSpecialLevelConfig() {
        return this.post('https://apigetway.track718.net/track/getSpecialLevelConfig', null, 200);
    }

    // 是否显示广告
    public adsIsShow(){
        return new Promise((resolve, reject) => {
            axios.get('https://apigetway.track718.net/v1/ads', {}).then(res => {
                //resolve(true);
                resolve(res.data.data);
            }).catch(res => {
                reject('');
            })
        })
    }

    // 订阅邮件
    public postSubscribeTrack(param: any) {
        return this.post('https://apigetway.track718.net/track/subscribeEmailNotice', param, 200);
    }
}
